import React, { useState } from "react"
import { styled } from "@mui/system"
import { Typography } from "@mui/material"
import GridLines from "../gridLines"
import BlogComponent from "./blogComponent"
import { navigate } from "gatsby"

const Wrapper = styled("div")({
  position: "relative",
  display: "grid",
  margin: "0 auto",
  maxWidth: "1280px",
  gridTemplateColumns: "repeat(3, 1fr)",
  "@media (max-width:576px)": {
    display: "flex",
    flexDirection: "column",
  },
})

const ContentContainer = styled("div")({
  gridColumnStart: 2,
  gridColumnEnd: 4,
  gridRowStart: 1,
  width: "100%",
  height: "auto",
  display: "flex",
  flexDirection: "column",
  rowGap: "100px",
  "@media (max-width:576px)": {
    padding: "0 20px",
    rowGap: "40px",
  },
})

const FilterContainer = styled("div")({
  position: "sticky",
  // TODO: Спросить Алексея про высоту залипания
  top: 50,
  gridRowStart: 1,
  width: "100%",
  height: "fit-content",
  "@media (max-width:576px)": {
    // TODO: Спросить Алексея про мобильную версию залипания
    top: 0,
    zIndex: 10,
    background: "white",
    width: "100vw",
    overflow: "scroll",
    msOverflowStyle: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    scrollbarWidth: "none",
    padding: "10px 20px",
    display: "flex",
    flexDirection: "row",
    gap: 47,
    marginBottom: 60,
  },
})

const FilterItem = styled("div")({
  textDecoration: "none",
  cursor: "pointer",
  display: "flex",
  marginBottom: 40,
  alignItems: "center",
  gap: 12,
  "@media (max-width:576px)": {
    gap: 0,
    marginBottom: 0,
    minWidth: "fit-content",
    display: "flex",
    flexDirection: "column-reverse",
  },
})

const FilterHr = styled("hr")({
  display: "flex",
  width: "31px",
  height: 4,
  margin: 0,
  background: "#0870E0",
  transition: "all 0.4s",
  "@media (max-width:576px)": {
    width: "100%",
    height: 2,
  },
})

const FilterText = styled(Typography)({
  display: "flex",
  fontFamily: "Heiti TC",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "28px",
  lineHeight: "135%",
  transition: "all 0.4s",
  "@media (max-width:576px)": {
    fontSize: 22,
  },
})

const Anchor = styled("div")({
  position: "absolute",
  top: "-50px",
  width: 0,
  height: 0,
  "@media (max-width:576px)": {
    top: 0
  },
})

const BlogBody = ({ data, newsTypes }) => {
  const [filter, setFilter] = useState("all-articles")
  const allNews = data
  const [filteredData, setFilteredData] = useState([...allNews.slice(0, 5)])
  const [loadMore, setLoadMore] = useState(false)
  const [hasMore, setHasMore] = useState(allNews.length > 5)

  const contentRef = React.useRef(null)

  const callbackFunction = entries => {
    const [entry] = entries
    if (entry.isIntersecting) {
      setLoadMore(true)
    }
  }

  React.useEffect(() => {
    const optionsObserve = {
      root: null,
      rootMargin: "0px 0px 0px 0px",
      threshold: 1.0,
    }
    let observerRefValue = null
    const observer = new IntersectionObserver(callbackFunction, optionsObserve)
    if (contentRef.current) {
      observer.observe(contentRef.current)
      observerRefValue = contentRef.current
    }
    return () => {
      if (observerRefValue) observer.unobserve(observerRefValue)
    }
  }, [contentRef])

  // Handle loading more articles
  React.useEffect(() => {
    if (loadMore && hasMore) {
      const currentLength = filteredData.length
      const filteredAll = filter === "all-articles" ? [...allNews] : [...allNews.filter(item => item.blog_type.Type === filter)]
      const isMore = currentLength < filteredAll.length
      const nextResults = isMore
        ? filteredAll.slice(currentLength, currentLength + 5)
        : []
        setFilteredData(data => [...data, ...nextResults])
        setLoadMore(false)
      }
  }, [loadMore, hasMore, allNews, filteredData, filter])

  //Check if there is more
  React.useEffect(() => {
    const isMore = filter === "all-articles" ? filteredData.length < allNews.length : filteredData.length < allNews.filter(item => item.blog_type.Type === filter).length
    setHasMore(isMore)
  }, [filteredData, allNews, filter])

  const filterData = (newsType) => {
    navigate("#blog")
    if (newsType === "all-articles") {
      setFilteredData([...allNews.slice(0, 5)])
      setFilter(newsType)
      return
    }
    const filteredAll = [...allNews.filter(item => item.blog_type.Type === newsType)]
    const newArr = [...filteredAll.slice(0, 5)]
    if (newArr.length <= filteredAll.length) {
      setHasMore(false)
    }
    setFilteredData(newArr)
    setFilter(newsType)
  }

  return (
    <>
      <Wrapper>
        <FilterContainer>
          <FilterItem
            onClick={e => filterData("all-articles")}
            key={`filter-all-articles`}
          >
            <FilterHr style={{ opacity: filter === "all-articles" ? 1 : 0 }} />
            <FilterText
              style={{ color: filter === "all-articles" ? "#0870E0" : "#222222" }}
            >
              All Articles
            </FilterText>
          </FilterItem>
          {newsTypes.map((item) => (
            <FilterItem
              onClick={e => filterData(item.Type.Type)}
              key={`filter-${item.Type.Type.toLowerCase()}`}
            >
              <FilterHr style={{ opacity: filter === item.Type.Type ? 1 : 0 }} />
              <FilterText
                style={{ color: filter === item.Type.Type ? "#0870E0" : "#222222" }}
              >
                {item.Type.Type}
              </FilterText>
            </FilterItem>
          ))}
        </FilterContainer>
        <ContentContainer>
          {filteredData.map((item, index) => (
            <BlogComponent item={item} key={`news-${index}`}/>
          ))}
          <div ref={contentRef} style={{ width: "100%", height: "0" }}></div>
        </ContentContainer>
        <Anchor id="blog"></Anchor>
      </Wrapper>
      <GridLines
        styles={{
          height: 87,
          heightMob: 30,
          marginTop: 112,
          marginTopMob: 73,
          marginBottom: 0,
          marginBottomMob: 0,
          color: "#F8F8F8",
        }}
      />
    </>
  )
}

export default BlogBody
