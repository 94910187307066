import React from "react"
import { styled } from "@mui/system"
import { Typography } from "@mui/material"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import getTime from "./timeConverter"

const NewsItem = styled("div")({
  display: "flex",
  flexDirection: "column",
  transition: "all 0.4s",
  "&:hover": {
    "p:first-of-type": {
      color: "#0870E0",
    },
    "& img": {
      filter: "grayscale(1)",
    },
    "& #blue-background": {
      opacity: 0.22,
    },
  },
})

const NewsType = styled("div")(({ theme }) => ({
  cursor: "default",
  display: "flex",
  justifyContent: "flex-start",
  marginRight: "inherit",
  marginBottom: "31px",
  "& hr": {
    color: theme.palette.secondary.blue,
    width: "100%",
    height: "4px",
    backgroundColor: theme.palette.secondary.blue,
    margin: "0.8em auto",
  },
  "@media (max-width:576px)": {
    marginRight: 0,
    marginBottom: "19px",
    "& hr": {
      width: "100%",
      height: "3px",
    },
  },
}))

const NewsHeader = styled(Typography)(({ theme }) => ({
  ...theme.typography.newsTitle,
  color: theme.palette.secondary.blue,
  whiteSpace: "nowrap",
  fontSize: "28px",
  marginRight: "19px",
  textTransform: "uppercase",
  "@media (max-width:1025px)": {
    fontSize: "1rem",
  },
  "@media (max-width:576px)": {
    marginRight: "17px",
    fontSize: "18px",
  },
}))

const ImageGatsby = styled(GatsbyImage)({
  zIndex: 1,
  position: "static",
  "& img": {
    transition: "all 0.4s",
  },
})

const ImageContainer = styled("div")({
  marginBottom: 24,
  position: "relative",
  maxHeight: 293,
  "@media (max-width:576px)": {
    marginBottom: 15,
  },
})

const BlueFilter = styled("div")({
  zIndex: 2,
  position: "absolute",
  width: "100%",
  height: "100%",
  background: "#0870E0",
  opacity: 0,
  transition: "all 0.4s",
})

const NewsTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.newsBody,
  fontSize: "32px",
  marginBottom: "15px",
  transition: "all 0.4s",
  "@media (max-width:576px)": {
    fontSize: "4.615vw",
    marginBottom: "20px",
  },
}))

const NewsFooter = styled(Typography)(({ theme }) => ({
  ...theme.typography.newsBody,
  fontSize: "22px",
  lineGeight: "190%",
  color: theme.palette.secondary.main,
  opacity: 0.6,
  "& span": {
    fontWeight: 700,
  },
  "@media (max-width:1025px)": {
    fontSize: "0.67rem",
  },
  "@media (max-width:576px)": {
    fontSize: "12px",
  },
}))

const BlogComponent = ({ item }) => {

  const blogURL = `/` + item.title.toLowerCase()
    .replace('.', '')
    .replace(/ /g,'-')
    .replace(/[^\w-]+/g,'') + `-${item.strapi_id}/`

  return (
    <NewsItem>
      <Link to={blogURL} style={{ cursor: "pointer", textDecoration: "none" }}>
        <NewsType>
          <NewsHeader>{item.blog_type.Type}</NewsHeader>
          <hr />
        </NewsType>
        <ImageContainer>
          <BlueFilter id="blue-background" />
          {item.image !== null ? (
            <ImageGatsby
              image={item.image?.localFile.childImageSharp.gatsbyImageData}
              imgStyle={{ transition: "all 0.4s" }}
              alt={item.image?.alternativeText ?? `news-image`}
            />
          ) : null}
        </ImageContainer>
          <NewsTitle>{item.title}</NewsTitle>
          <NewsFooter>
          {getTime(item.publication_date)}
          {item.source_name ? " - " : null}
          <span>{item.source_name}</span>
          </NewsFooter>
      </Link>
    </NewsItem>
  )
}

export default BlogComponent
