import * as React from "react"
import Seo from "../components/seo"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import BlogHeader from "../components/blog/blogHeader"
import BlogBody from "../components/blog"

const BlogPage = ({ data }) => {
  const blogData = data.allStrapiNewsItem.edges.map(item => item.node)
  const newsTypesData = data.allStrapiNewsType.edges[0].node.Types
  return (
    <Layout headerDark={false} firstScreen={false}>
      <Seo title="Blog" description={`In this section we share significant news of our portfolio companies together with insights and first-hand views from our partners.`}/>
      <BlogHeader />
      <BlogBody data={blogData} newsTypes={newsTypesData} />
    </Layout>
  )
}

export default BlogPage

export const query = graphql`
  query BlogPageQuery {
    allStrapiNewsItem(sort: { fields: publication_date, order: DESC }) {
      edges {
        node {
          title
          publishedAt
          strapi_id
          publication_date
          image {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          source_name
          source_link
          blog_type {
            Type
          }
        }
      }
    }
    allStrapiNewsType {
      edges {
        node {
          Types {
            strapi_id
            Type {
              Type
            }
          }
        }
      }
    }
  }
`
