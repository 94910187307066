import React from "react"
import { styled } from "@mui/system"
import { Typography } from "@mui/material"
import GridLines from "../gridLines"

const Wrapper = styled("div")({
  width: "100%",
  maxWidth: "1280px",
  margin: "0 auto",
  display: "flex",
  flexDirection: "column",
  alignContent: "center",
  alignItems: "center",
  justifyContent: "space-between",
  height: "100%",
  padding: "28px 0",
  "@media (max-width:1024px)": {
    maxWidth: "100vw",
    padding: "86px 20px 28px 20px"
  },
})

const HeaderText = styled(Typography)({
  fontFamily: "Heiti TC",
  fontSize: "66px",
  fontWeight: "400",
  lineHeight: "135%",
  background: "linear-gradient(107.14deg, #0870E0 5.78%, #54A3F9 93.9%)",
  color: "transparent",
  WebkitBackgroundClip: "text",
  backgroundClip: "text",
  paddingBottom: "48px",
  "@media(max-width: 1025px)": {
    paddingBottom: "36px",
    fontSize: "3rem",
    lineHeight: "2.8rem",
  },
  "@media(max-width: 577px)": {
    paddingBottom: "44px",
    fontSize: "10.25vw",
    lineHeight: "135%",
  },
})

const BodyText = styled(Typography)(({ theme }) => ({
  fontFamily: "Merriweather",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "21px",
  lineHeight: "190%",
  textAlign: "center",
  color: theme.palette.secondary.main,
  maxWidth: "907px",
  display: "block",
  padding: "0 5px",
  "& span": {
    background: "linear-gradient(107.14deg, #0870E0 5.78%, #54A3F9 93.9%)",
    color: "transparent",
    WebkitBackgroundClip: "text",
    backgroundClip: "text",
  },
  "@media(max-width: 1025px)": {
    maxWidth: "90vw",
    fontSize: "1.2rem",
  },
  "@media(max-width: 577px)": {
    maxWidth: "90vw",
    fontSize: "4.615vw",
  },
}))

const BlogHeader = () => {
  return (
    <>
    <Wrapper>
      <HeaderText>Blog</HeaderText>
      <BodyText>
      In this section we share {" "}
        <span>
        significant news of our portfolio companies <br/>
        </span>{" "}
        together with {" "} 
            <span>
        insights and first-hand views
            </span> {" "} from our partners.
      </BodyText>
    </Wrapper>
    <GridLines 
        styles={{
            height: 174,
            heightMob: 60,
            marginTop: 0,
            marginTopMob: 0,
            marginBottom: 25,
            marginBottomMob: 25,
            color: "#F8F8F8",
          }}
    />
    </>
  )
}

export default BlogHeader
