

const getTime = (time) => {
    var timeNow = new Date()
    var timeNews = new Date(time)
    var delta_time = Math.round(Math.abs((timeNow - timeNews) / 1000 / 60 / 60))
    var timeString
    if (delta_time < 24) {
      if (delta_time === 1 || delta_time === 0) {
        timeString = "1 hour ago"
      } else {
        timeString = delta_time.toString() + " hours ago"
      }
    } else {
      timeString = timeNews.toLocaleString("en-us", {
        day: "numeric",
        month: "short",
        year: "numeric",
      })
    }
    return timeString
}

export default getTime